<script setup lang="ts">
import { computed } from 'vue';
import { BLOCK_HEADER_LAYOUTS } from '@zyro-inc/site-modules/constants';

interface Props {
	layout: string;
	mobileLayout: string;
	isLogoVisible: boolean;
	isLanguageSwitcherVisible: boolean;
	areSocialIconLinksVisible: boolean;
	isCartVisible: boolean;
	isButtonVisible: boolean;
	isMobileMenuDropdownOpen: boolean;
}

const props = defineProps<Props>();

// Construct the grid columns for the non-centered layout, based on the visibility of the elements.
const visibleNonCenteredRightElementsCount = computed(() => [
	props.isLanguageSwitcherVisible,
	props.areSocialIconLinksVisible,
	props.isCartVisible,
	props.isButtonVisible,
].filter(Boolean).length);
const nonCenteredLayoutColumns = computed(() => `${props.isLogoVisible ? 'min-content' : ''} auto ${visibleNonCenteredRightElementsCount.value > 0 ? `repeat(${visibleNonCenteredRightElementsCount.value}, min-content)` : ''}`);
</script>

<template>
	<!--
		The purpose of having this layouts element is allowing to change the HTML order of the elements easily.
		If we'd try doing this within the main `BlockHeader.vue` file, it would result in loads of boilerplate duplication,
		constantly copying over the same props passing, event listeners and etc.
		Using slots, this issue is avoided - we can easily see what's the order for which layout is, leading to better maintainability.
		Only downside - CSS styling, as we have to use `:deep()` to style the elements as they're within parent component, and class names
		can only be seen there.
	-->
	<div
		v-if="props.layout === BLOCK_HEADER_LAYOUTS.DESKTOP_1 || props.layout === BLOCK_HEADER_LAYOUTS.DESKTOP_2"
		:class="`block-header-layout-desktop block-header-layout-desktop--${props.layout}`"
	>
		<slot name="logo" />
		<slot name="nav" />
		<slot name="language-switcher" />
		<slot name="social-icons" />
		<slot name="cart" />
		<slot name="button" />
	</div>

	<div
		v-else-if="props.layout === BLOCK_HEADER_LAYOUTS.DESKTOP_3"
		class="block-header-layout-desktop block-header-layout-desktop--desktop-3"
	>
		<slot name="logo" />
		<slot name="nav" />
		<div class="block-header-layout-desktop__right-side">
			<slot name="language-switcher" />
			<slot name="social-icons" />
			<slot name="cart" />
			<slot name="button" />
		</div>
	</div>

	<div
		v-else-if="props.layout === BLOCK_HEADER_LAYOUTS.DESKTOP_4"
		class="block-header-layout-desktop block-header-layout-desktop--desktop-4"
	>
		<slot name="nav" />
		<slot name="logo" />
		<div class="block-header-layout-desktop__right-side">
			<slot name="language-switcher" />
			<slot name="social-icons" />
			<slot name="cart" />
			<slot name="button" />
		</div>
	</div>

	<div
		v-else-if="props.layout === BLOCK_HEADER_LAYOUTS.DESKTOP_5"
		class="block-header-layout-desktop block-header-layout-desktop--desktop-5"
	>
		<slot name="logo" />
		<slot name="social-icons" />

		<slot name="nav" />
		<div class="block-header-layout-desktop__right-side">
			<slot name="language-switcher" />
			<slot name="cart" />
			<slot name="button" />
		</div>
	</div>

	<div
		class="block-header-layout-mobile"
		:class="`block-header-layout-mobile--${props.mobileLayout}`"
	>
		<slot name="logo" />
		<slot name="cart" />
		<slot name="hamburger-menu" />
		<div
			class="block-header-layout-mobile__dropdown"
			:class="{ 'block-header-layout-mobile__dropdown--open': props.isMobileMenuDropdownOpen }"
		>
			<slot name="nav" />
			<slot name="language-switcher" />
			<slot name="social-icons" />
			<slot name="button" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";
$default-item-spacing: 16px;

.block-header-layout-desktop {
	$this: &;

    z-index: $z-index-site-engine-header;
    justify-self: center;
    align-items: center;
    width: 100%;
    max-width: 1224px;
	padding: var(--padding, 24px 0);
    display: grid;

	&__right-side {
		display: flex;
		gap: var(--element-spacing);
		margin-left: $default-item-spacing;
		align-items: center;
		justify-content: flex-end;
		grid-area: right-side;

		&:empty {
			display: none;
		}
	}

	&--desktop-1 {
		grid-auto-flow: column;
		grid-template-columns: v-bind(nonCenteredLayoutColumns);

		:deep() {
			.block-header {
				&__logo {
					margin-right: $default-item-spacing;
				}

				&__nav-links {
					justify-content: flex-end;
				}

				&__button, &__cart, &__social-icons, &__language-switcher {
					margin-left: var(--element-spacing);
				}
			}
		}
	}

	&--desktop-2 {
		grid-auto-flow: column;
		grid-template-columns: v-bind(nonCenteredLayoutColumns);

		:deep() {
			.block-header {
				&__logo {
					margin-right: var(--element-spacing);
				}

				&__nav-links {
					justify-content: flex-start;
				}

				&__button, &__cart, &__social-icons, &__language-switcher {
					margin-left: var(--element-spacing);
				}
			}
		}
	}

	&--desktop-3 {
		grid-template-columns: 1fr auto 1fr;
		grid-template-areas: "logo nav right-side";

		:deep() {
			.block-header {
				&__logo {
					grid-area: logo;
					margin-right: $default-item-spacing;
				}

				&__nav-links {
					justify-content: center;
				}

				&__nav {
					grid-area: nav;
				}

				&__right-side {
					grid-area: right-side;
				}

				&__button, &__cart, &__social-icons, &__language-switcher {
					margin-left: var(--element-spacing);
				}
			}
		}
	}

	&--desktop-4 {
		grid-template-columns: 1fr auto 1fr;
		grid-template-areas: "nav logo right-side";

		:deep() {
			.block-header {
				&__logo {
					grid-area: logo;
				}

				&__nav {
					grid-area: nav;
				}

				&__right-side {
					grid-area: right-side;
				}

				&__nav-links {
					margin-right: $default-item-spacing;
				}

				&__button, &__cart, &__social-icons, &__language-switcher {
					margin-left: var(--element-spacing);
				}
			}
		}
	}

	&--desktop-5 {
		grid-template-areas:
			"logo logo logo"
			"social-icons nav right-side";
		grid-template-columns: 1fr auto 1fr;
		gap: var(--element-spacing);

		:deep() {
			.block-header {
				&__logo {
					justify-self: center;
					grid-area: logo;
				}

				&__social-icons {
					grid-area: social-icons;
				}

				&__nav {
					grid-area: nav;
				}

				&__right-side {
					grid-area: right-side;
				}

				&__nav-links {
					justify-content: center;
				}

				&__social-icons {
					margin-right: $default-item-spacing;
				}
			}
		}
	}
}

.block-header-layout-mobile {
	display: none;
}

@include site-engine-mobile {
	.block-header-layout-desktop {
		display: none;
	}

	.block-header-layout-mobile {
		z-index: $z-index-site-engine-header;
		display: grid;
		padding: var(--m-padding, var(--padding));
		align-items: center;

		&__dropdown {
			visibility: hidden;
			left: 0;
			padding: 0 16px;
			position: absolute;
			display: flex;
			flex-direction: column;
			background: var(--background-color);
			top: 100%;
			width: 100%;
			gap: var(--m-element-spacing, var(--element-spacing));
			align-items: flex-end;
			overflow-y: hidden;
			height: 0;
			transition: 0.3s 0.1s height ease-out, 0.5s 0.1s padding, 0.6s visibility;
			scrollbar-width: none;

			&--open {
				visibility: visible;
				height: 100vh;
				padding: 24px 16px;
				// scrollbar-width not supported on Safari (and possibly other browsers), so we show the scrollbar after transition
				animation: show-overflow 0s 0.5s forwards;
			}
		}

		:deep() {
			.block-header {
				&__hamburger-menu {
					grid-area: hamburger-menu;
				}

				&__logo {
					grid-area: logo;
				}

				&__cart {
					grid-area: cart;
					overflow: hidden;
				}

				&__nav-links {
					flex-direction: column;
					gap: var(--m-link-spacing, var(--link-spacing));
					list-style: none;
					text-align: end;

					.block-header-item {
						.block-header-item__label > .item-content-wrapper {
							justify-content: flex-end;
						}

						.block-header-item__label > .block-header-item__dropdown-area > .block-header-item__dropdown {
							align-items: flex-end;
						}
					}
				}

				&__language-switcher {
					.language-switcher__button {
						justify-content: flex-end
					}
				}

				&__button {
					flex: none;
				}
			}
		}

		&--mobile-1 {
			grid-template-columns: auto minmax(0, max-content) max-content;
			grid-template-areas: "logo cart hamburger-menu";

			:deep() {
				.block-header {
					&__hamburger-menu {
						margin-left: var(--m-element-spacing, var(--element-spacing));
					}
				}
			}
		}

		&--mobile-2 {
			grid-template-columns: max-content max-content auto;
			grid-template-areas: "hamburger-menu logo cart";
			justify-items: end;

			:deep() {
				.block-header {
					&__hamburger-menu {
						margin-right: var(--m-element-spacing, var(--element-spacing));
					}
				}
			}
		}

		&--mobile-3 {
			grid-template-columns: 1fr auto 1fr;
			grid-template-areas: "hamburger-menu logo cart";

			:deep() {
				.block-header {
					&__cart {
						justify-self: end;
					}
				}
			}
		}
	}
}

@keyframes show-overflow {
	to {
		overflow-y: auto;
	}
}
</style>
