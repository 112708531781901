<template>
	<header
		ref="headerRef"
		:class="{
			[BLOCK_HEADER_CLASS]: true,
			'block-header--with-shadow': isWithShadow,
		}"
		:style="computedStyles"
	>
		<BlockHeaderBackground
			v-if="props.background"
			:type="props.background.current"
			:image-url="props.backgroundImageUrl"
			:color="props.background.color"
			:overlay-opacity="Number(props.background['overlay-opacity'])"
			:is-transparent="isCurrentlyTransparent"
		/>

		<BlockHeaderLayout
			:layout="props.headerLayout"
			:mobile-layout="props.mHeaderLayout"
			:is-logo-visible="props.isLogoVisible"
			:is-language-switcher-visible="props.isLanguageSwitcherVisible"
			:are-social-icon-links-visible="isSocialIconsLinksVisible"
			:is-cart-visible="props.isCartVisible"
			:is-button-visible="isButtonVisible"
			:is-mobile-menu-dropdown-open="props.isOpen"
		>
			<template #hamburger-menu>
				<ZyroHamburger
					v-if="props.items.length || props.isLanguageSwitcherVisible"
					v-qa="'builder-siteheader-btn-hamburger'"
					class="block-header__hamburger-menu"
					:is-open="props.isOpen"
					@click.stop="$emit('toggle-visibility')"
				/>
			</template>
			<template #logo>
				<BlockHeaderLogo
					v-if="props.isLogoVisible"
					class="block-header__logo"
					:logo-src="props.logoSrc"
					:logo-svg="props.logoSvg"
					:href="props.logoHref"
					:site-name="props.siteName"
					:max-width="props.logoMaxWidth"
					:max-width-mobile="props.logoMaxWidthMobile"
					:height="props.logoHeight"
					:height-mobile="props.logoHeightMobile"
					:is-optimized="props.isLogoOptimized"
					:object-position="props.logoObjectPosition"
					:object-position-mobile="props.logoObjectPositionMobile"
					@click="$emit('logo-click')"
				/>
			</template>
			<template #nav>
				<nav
					class="block-header__nav"
					@click="emit('navigation-click')"
				>
					<ul
						v-qa="'builder-siteheader-emptyspace'"
						class="block-header__nav-links"
					>
						<BlockHeaderItem
							v-for="item in props.items"
							:key="item.id"
							:item="item"
							:are-dropdowns-hidden="props.areDropdownsHidden"
						/>
					</ul>
				</nav>
			</template>
			<template #social-icons>
				<GridSocialIcons
					v-if="props.socialIconsLinks.length"
					class="block-header__social-icons"
					:links="props.socialIconsLinks"
				/>
			</template>
			<template #language-switcher>
				<LanguageSwitcher
					v-if="props.isLanguageSwitcherVisible"
					class="block-header__language-switcher"
					:class="{ 'mouse-disabled': props.areDropdownsHidden }"
					:languages="props.languageSwitcherLanguages"
					:current-locale="props.currentLocale"
				/>
			</template>
			<template #cart>
				<BlockHeaderCart
					v-if="props.isCartVisible"
					class="block-header__cart"
					:cart-text="props.cartText"
					:cart-url="props.cartUrl"
					:items-in-cart="props.itemsInCart"
					:cart-icon-size="props.cartIconSize"
					@cart-click="$emit('cart-click')"
				/>
			</template>
			<template #button>
				<BlockHeaderButton
					v-if="isButtonVisible && props.buttonElementId"
					class="block-header__button"
					:element-id="props.buttonElementId"
					:data="props.buttonElementData"
				/>
			</template>
		</BlockHeaderLayout>
	</header>
</template>

<script setup lang="ts">
import LanguageSwitcher from '@zyro-inc/site-modules/components/LanguageSwitcher.vue';
import ZyroHamburger from '@zyro-inc/site-modules/components/ZyroHamburger.vue';
import BlockHeaderBackground from '@zyro-inc/site-modules/components/blocks/header/BlockHeaderBackground.vue';
import BlockHeaderCart from '@zyro-inc/site-modules/components/BlockHeaderCart.vue';
import BlockHeaderItem from '@zyro-inc/site-modules/components/BlockHeaderItem.vue';
import BlockHeaderLogo from '@zyro-inc/site-modules/components/BlockHeaderLogo.vue';
import BlockHeaderLayout from '@zyro-inc/site-modules/components/BlockHeaderLayout.vue';
import { BLOCK_HEADER_CLASS } from '@zyro-inc/site-modules/constants';
import {
	enableBodyScroll,
	disableBodyScroll,
} from 'body-scroll-lock';
import { useStickyTrigger } from '@zyro-inc/site-modules/use/useStickyTrigger';
import { onClickOutside } from '@vueuse/core';
import {
	onMounted,
	ref,
	computed,
	watch,
	StyleValue,
} from 'vue';
import GridSocialIcons from '@zyro-inc/site-modules/components/elements/social-icons/GridSocialIcons.vue';
import {
	SiteLanguageSwitcherLanguage,
	SiteBlockHeaderItem,
	GridSocialIconLink,
} from '@zyro-inc/site-modules/types/index';
import BlockHeaderButton from '@zyro-inc/site-modules/components/blocks/header/BlockHeaderButton.vue';

interface Props {
	siteName?: string;
	headerLayout: string;
	mHeaderLayout: string;
	languageSwitcherLanguages: SiteLanguageSwitcherLanguage[];
	items: SiteBlockHeaderItem[];
	logoHref: string;
	logoSrc?: string;
	logoSvg?: string;
	backgroundImageUrl?: string;
	isOpen?: boolean;
	isSticky?: boolean;
	isTransparent?: boolean;
	isLogoVisible?: boolean;
	isCartVisible?: boolean;
	isLanguageSwitcherVisible?: boolean;
	currentLocale?: string;
	backgroundColor?: string;
	navLinkTextColor?: string;
	navLinkTextColorHover?: string;
	cartText?: string;
	cartUrl?: string;
	itemsInCart?: number;
	areDropdownsHidden?: boolean;
	isLogoOptimized?: boolean;
	background: Record<string, string>;
	cartIconSize: string;
	logoMaxWidth: string;
	logoHeight: number;
	logoHeightMobile: number;
	logoMaxWidthMobile: string;
	logoObjectPosition: string;
	logoObjectPositionMobile: string;
	fontFamily: string;
	fontWeight: number;
	socialIconsLinks?: GridSocialIconLink[];
	socialIconsStyles?: Record<string, string>;
	buttonElementId: string,
	buttonElementData?: Record<string, string>,
}

const props = withDefaults(defineProps<Props>(), {
	siteName: '',
	isOpen: false,
	isLogoVisible: false,
	isLogoOptimized: false,
	logoSrc: '',
	logoSvg: '',
	cartText: '',
	cartUrl: '',
	currentLocale: '',
	backgroundColor: '',
	navLinkTextColor: '',
	navLinkTextColorHover: '',
	itemsInCart: 0,
	socialIconsLinks: () => [],
	socialIconsStyles: () => ({}),
	buttonElementData: () => ({}),
});

const emit = defineEmits<{
	'toggle-visibility': [],
	'cart-click': [],
	'logo-click': [],
	'button-click': [],
	'navigation-click': [],
	'social-icons-click': [],
	'language-switcher-click': [],
}>();

const { hasUserScrolled } = useStickyTrigger();
const headerRef = ref<HTMLElement | null>(null);
const isSocialIconsLinksVisible = computed(() => props.socialIconsLinks.length > 0);
const isButtonVisible = computed(() => !!Object.keys(props.buttonElementData).length);
const isWithShadow = computed(() => hasUserScrolled.value && props.isSticky);
const shouldForceTransparency = computed(() => (props.isSticky && !hasUserScrolled.value) || !props.isSticky);
const isCurrentlyTransparent = computed(() => props.isTransparent && shouldForceTransparency.value && !props.isOpen);

const computedStyles = computed<StyleValue>(() => ({
	'--text-font-family': props.fontFamily,
	'--text-font-weight': props.fontWeight,
	'--nav-link-text-color': props.navLinkTextColor || 'var(--nav-link-color)',
	'--nav-link-text-color-hover': props.navLinkTextColorHover || 'var(--nav-link-color-hover)',
	...(isSocialIconsLinksVisible.value && {
		'--space-between-icons': '16px',
		'--icon-color': props.socialIconsStyles['icon-color'],
		'--icon-size': props.socialIconsStyles['icon-size'],
		'--icon-color-hover': props.socialIconsStyles['icon-color-hover'],
	}),
}));

onMounted(() => {
	onClickOutside(headerRef, () => {
		if (props.isOpen) {
			emit('toggle-visibility');
		}
	});
});

watch(() => props.isOpen, (isOpen) => {
	if (!headerRef.value) {
		return;
	}

	if (isOpen) {
		disableBodyScroll(headerRef.value);
	} else {
		enableBodyScroll(headerRef.value);
	}
});
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";
$default-item-spacing: 16px;

.block-header {
	display: grid;
	transition: box-shadow 0.15s linear;
	position: relative;
	z-index: $z-index-site-engine-header;

	&__nav-links {
		display: flex;
		list-style: none;
		flex-flow: row wrap;
		justify-content: flex-start;
		column-gap: var(--link-spacing);
		row-gap: $default-item-spacing;
	}

	&__social-icons {
		--space-between-icons: 16px;
	}

	&--with-shadow {
		box-shadow: $box-shadow;
	}
}

@include site-engine-mobile {
	.block-header {
		padding: 0 16px;
	}
}
</style>
